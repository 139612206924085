import Accordion from 'accordion-js';
import { isMobileOrTablet } from './helpers';

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    if (isMobileOrTablet()) {
      for(var i = 1; i <= 4; i++){
        if (document.querySelector('#footer-' + i)) {
          new Accordion('#footer-' + i, {
            duration: 300,
            ariaEnabled: true,
            collapse: true,
            showMultiple: false,
            openOnInit: [],
            activeClass: 'collapsed'
          });
        }
      }
    }
  }
};